<template>
  <div>
    <div class="report-user-card">
      <section class="column-wrapper-main">
        <div class="left-column-main">
          <DisplayAvatar :avatar="getAvatar" :width="`4rem`" :height="`4rem`" />
        </div>

        <div class="right-column-main">
          <div>
            <h6 class="font-extrabold text-14px text-muted">Reported By</h6>
            <h2 class="font-extrabold text-22px text-oDark">
              {{ details.user ? details.user.full_name : '--' }}
            </h2>
          </div>

          <div v-if="details.user">
            <div class="points green">+{{ details.user.add_point }}</div>
          </div>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            Report ID
          </h5>

          <h5 class="text-value">
            {{ $truncate(details.id, -5) }}
          </h5>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            Report Date
          </h5>

          <h5 class="text-value">
            {{
              details.report_date
                ? getFormattedDateTime(details.report_date)
                : '--'
            }}
          </h5>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            Transaction ID
          </h5>

          <div class="text-value">
            <router-link
              v-if="details.transaction_id"
              :to="{
                name: 'ViewTrip',
                params: { id: details.transaction_id },
              }"
              target="_blank"
            >
              <div class="font-extrabold text-blue-500 text-14px">
                {{ $truncate(details.transaction_id, -5) }}
              </div>
            </router-link>
            <div v-else>
              --
            </div>
          </div>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            Trip ID
          </h5>

          <div class="text-value">
            <router-link
              v-if="details.trip"
              :to="{
                name: 'ViewTrip',
                params: { id: details.trip.id },
              }"
              target="_blank"
            >
              <div class="font-extrabold text-blue-500 text-14px">
                {{ $truncate(details.trip.id, -5) }}
              </div>
            </router-link>
            <div v-else>
              --
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { getFormattedDateTime } from '@/utils/datetime'

export default {
  name: 'ReporterUserBlock',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
  },
  computed: {
    getAvatar() {
      return this.details.user ? this.details.user.profile_pic : null
    },
  },

  methods: {
    getFormattedDateTime,
  },
}
</script>

<style lang="scss" scoped>
@import '$report-user-card';
</style>
